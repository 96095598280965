section.block-solutions-overview {

}

.solutions-overview {
	&__slide {
		&-content {
			h5 {
				color: $secondary;
				font-weight: 700;
			}

			.btn-link {
				padding: 0;
			}
		}
	}
}
