section.block-image-content-2-cols > .section-bd article ul,
section.block-service-cards > .section-bd article {
	ul {
		padding-left: 1rem;
	}

	li {
		list-style: initial;
		min-height: unset;
		background: none;
		padding-left: 0;
	}
}
