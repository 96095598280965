@media (max-width: 991.98px) {
  header.global-header .header-navbar .header-navbar-main .header-lang .header-lang-menu > .menu-item-has-children > .sub-menu-wrap {
    top: 3.5rem;
  }
}

@media (max-width: 991.98px) {
  .header-hamburger {
    display: block;
  }
}

@media (max-width: 991.98px) {
  header.global-header .header-navbar .header-navbar-main .header-nav {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .header-mobile-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .header-mobile-nav.active {
    transform: translate(0, 0px);
    opacity: 1;
    pointer-events: auto;
  }
}

.header-mobile-nav .box-nav {
  width: 100%;
  height: 100%;
}

// ------------------------------------------------------------------

.header-mobile-nav .box-nav .box-hd nav .mobile-lang-menu {
  padding-right: 40px;
}

header.global-header .header-navbar .header-navbar-main .header-nav {
  .header-nav-menu > .menu-item {
    @include min(992) {
      @include max(1199) {
        margin-left: 1.5rem;
      }
    }

    > a {
      font-weight: 400;
    }
  }
}

header.global-header .header-navbar .header-navbar-main .header-lang .header-lang-menu {
  > .menu-item > a {
    font-weight: 400;
  }

  > .menu-item-has-children > .sub-menu-wrap .menu-item a {
    font-weight: 400;
  }
}
