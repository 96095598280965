// Shortened Transition
@mixin tr($t: $default-transition) {
	transition: $t;
}

@mixin fs($size-value: 16) {
	font-size: rem($size-value);
}

@mixin lh($size-value: 16) {
	line-height: rem($size-value);
}

@mixin ff($fs, $lh, $fw: 400) {
	@include fs($fs);
	@include lh($lh);
	font-weight: $fw;
}

@mixin text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin hide-text {
	font: 0 / 0 a;
	text-shadow: none;
	color: transparent;
}

@mixin selection {
	& ::selection {
		@content;
	}
}

// All headings
@mixin headings {
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		@content;
	}
}

// Text truncate
// Requires inline-block or block for proper styling
@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// No autofill color for inputs
@mixin no-autofill {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 0s 9999s; // use a really large delay here
	}

	// Change text in autofill textbox
	&:-webkit-autofill {
		-webkit-text-fill-color: $b;
	}
}

// Responsive hover
@mixin hover($bp: lg) {
	@include min($bp) {
		&:hover {
			@content
		}
	}
}

// Placeholder for all browsers
@mixin placeholder {
	&:-ms-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&::-webkit-input-placeholder {
		@content;
	}

	&::placeholder {
		@content;
	}
}

// Custom scrollbar styling
@mixin scrollbar($width: 10px, $color-track: $b, $color-border: #ccc) {
	&::-webkit-scrollbar {
		width: $width;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba($color-track, 0.2);
		border-radius: calc($width / 2);
	}
	&::-webkit-scrollbar-thumb {
		width: $width;
		height: 60px;
		border-radius: calc($width / 2);
		border: 1px solid $color-border;
		background: $color-border;
		-webkit-box-shadow: inset 0 0 6px rgba($color-track, 0.3);
	}
}

// Clear default styling for button elements
@mixin unstyled-button {
	background-color: transparent;
	color: currentColor;
	border: 0;
	margin: 0;
	padding: 0;
	outline: unset;
	text-decoration: none;
}

// Clear default styling for list elements
@mixin unstyled-list {
	list-style: none;
	margin: 0;
	padding: 0;
}


