.form-group {
  a:not([class]) {
    color: $secondary;

    &:hover {
      text-decoration: underline;
    }
  }
}

.form-control {
  border-width: 2px;
  border-radius: 4px;

  @include placeholder {
    color: rgba($b, 0.5);
    transition: 0.3s;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    }
  }

  &.wpcf7-not-valid {
    border-color: $red;

    + .wpcf7-not-valid-tip {
      font-size: rem(14);
    }
  }
}

.hbspt-form:not(#none) {
  fieldset {
    max-width: unset;
  }

  .input {
    margin-bottom: 1rem;
  }

  .hs-form-booleancheckbox-display {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    input[type="checkbox"] {
      position: relative;
      top: 0.4em;
    }

    > span {
      margin: 0;
    }
  }

  .hs-input:not(input[type="checkbox"]):not(input[type="radio"]) {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.375rem + 2px);
    padding: 0.6875rem 0.75rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: #023970;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #EAEDF2;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    @include placeholder {
      color: rgba($b, 0.5);
      font-style: normal;
      transition: 0.3s;
    }

    &:focus {
      color: #023970;
      background-color: #fff;
      border-color: #044ed7;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(209, 84, 33, 0.25);

      @include placeholder {
        opacity: 0;
      }
    }

    &.hs-fieldtype-textarea {
      height: auto;
    }

    &.error {
      border-color: $red;
    }
  }

  .hs-error-msgs {
    color: $red;
    font-size: 14px;
  }

  .inputs-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .hs-form-checkbox,
  .hs-form-radio {
    label {
      display: flex;
      gap: 8px;
    }
  }

  .hs-richtext {
    a:not([class]) {
      color: $secondary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .hs-submit {
    margin-top: 1rem;
  }

  .hs-button {
    @extend .btn;
    @extend .btn-primary;
    color: $w;
  }
}

