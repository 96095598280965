.hero {
	@include max(md) {
		display: flex;
		flex-direction: column-reverse;
	}

	&__bg {
		width: 100%;
		height: 56vw;
		max-height: rem(210);
		position: relative;

		@include min(md) {
			height: 100%;
			max-height: unset;
			position: absolute;
		}

		.mask-element {
			height: 100%;
			object-position: right;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
		}
	}

	&__content {
		height: auto;
		padding: 0;
		position: relative;
		z-index: 2;

		&-decor {
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			@include min(md) {
				width: 15vw;
				transform: none;
				z-index: 1;
			}

			@include min(1400) {
				width: calc(50vw - 600px);
			}
		}

		&-inner {
			max-width: unset;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: rem(40 0);
			position: relative;

			@include min(md) {
				border-radius: rem(0 250 250 0);
				padding-right: 16%;
			}

			@include min(md) {
				min-height: rem(478);
			}

			h1 {
				font-weight: 300;
				padding-bottom: rem(20);
				margin-bottom: rem(23);
				position: relative;

				&:after {
					content: '';
					display: block;
					width: rem(60);
					height: 3px;
					background-color: $primary;
					border-radius: 3px;
					position: absolute;
					top: 100%;
					left: 0;
				}
			}
		}
    
    &-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: rem(10);
    }
	}
}

.hero.block-hero {
  .hero__bg {
    img {
      height: auto !important;
      bottom: 0;
    }
  }
}
