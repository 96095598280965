body {
	color: $navy;
	font-size: rem(18);
	font-weight: 400;
	letter-spacing: 0.029em;
	line-height: 1.5;
}

article {
	p,
	li {
		font-size: rem(18);
		line-height: 1.5;
	}
}
