.block-compare-models {
  .hbspt-form:not(#none) {
    .hs-input[type="email"]:not(#none) {
      border: none;
      border-radius: 0;
      font-weight: 300;
      color: #004593;
      font-size: 1.25rem;
      padding: 0;
      line-height: 1;
      border-bottom: 1px solid #004593;
      background: none !important;
      outline: none;

      @include placeholder {
        font-style: italic;
        color: #004593;
      }
    }
  }
}
