.site-footer {
  background-color: $black;
  color: $w;
  line-height: 1.5;
  overflow: hidden;

  &__main {
    background-color: $navy;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      height: rem(186);
      position: absolute;
      top: 0;
      z-index: 0;

      @include min(lg) {
        height: 100%;
      }
    }

    &:before {
      width: 120px;
      background-color: $primary;
      right: 0;

      @include min(lg) {
        width: rem(225);
      }
    }

    &:after {
      width: 186px;
      // combined shape: circle + rectangle:
      -webkit-mask: radial-gradient(circle closest-side, $navy 100%, transparent), linear-gradient(90deg, $navy 0 0) 0% 100% / 50% 100% no-repeat;
      background: $navy;
      right: rem(16);

      @include min(lg) {
        width: auto;
        aspect-ratio: 1 / 1;
        right: rem(10);
      }
    }
  }

  &__socials {
    position: relative;
    display: flex;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 100%;
      background-color: $purple;
      position: absolute;
      top: 0;
    }

    &:before {
      width: 20vw;
      right: 100%;
    }

    &:after {
      width: calc(100% - rem(16));
      border-radius: 0 100px 100px 0;
      left: 0;

      @include min(lg) {
        border-radius: 0 rem(120) rem(120) 0;
      }
    }
  }

  &__bottom {
    background-color: $black;
    padding: rem(30 0 40);

    @include min(md) {
      padding-bottom: rem(30);
    }

    @include min(lg) {
      padding: rem(60 0);
    }

    @include min(xl) {
      padding: rem(32 0);
    }
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @include min(md) {
      flex-direction: row;
      align-items: center;
      gap: rem(32);
    }

    &-text {
      font-size: rem(12);
      letter-spacing: 0.022em;

      @include min(md) {
        max-width: rem(315);
      }

      @include min(lg) {
        max-width: rem(300);
      }
    }
  }

  &__logo {
    flex-shrink: 0;
    width: rem(126);

    img {
      width: 100%;
    }
  }
}

.socials {
  height: rem(186);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $navy;
  position: relative;
  z-index: 2;

  @include min(lg) {
    height: unset;
    padding-top: rem(30);
    padding-bottom: rem(30);
  }

  @include min(xl) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(32);
    padding-top: rem(40);
    padding-bottom: rem(40);
  }

  &__title {
    display: block;
    font-size: rem(18);
    font-weight: 700;
    letter-spacing: 0.022em;
    margin-bottom: rem(20);

    @include min(xl) {
      margin-bottom: 0;
    }
  }

  &__list {
    display: flex;
    gap: rem(33);

    @include min(xl) {
      gap: rem(27);
    }
  }

  &__item {
    width: rem(27);
    height: rem(27);
    transition: opacity $time;

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      opacity: 1;
    }

    @include hover {
      opacity: 0.7;
    }
  }
}

.footer-menu__wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(30);
  padding: rem(30 0);

  @include min(lg) {
    max-width: rem(800);
    min-height: rem(235);
    display: flex;
    flex-direction: row;
    gap: 0;
    padding: rem(24 0);
  }

  @include min(xl) {
    padding-left: rem(28);
  }
}

.footer-menu {
  @extend %list-unstyled;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(5);
  font-size: rem(16);

  @include min(lg) {
    flex-grow: 1;
  }

  &__title {
    > span {
      display: block;
      font-size: rem(14);
      font-weight: 700;
      letter-spacing: 0.1em;
      border-bottom: 1px solid $purple;
      padding-bottom: rem(8);
      margin-bottom: rem(2);
      text-transform: uppercase;
    }
  }

  &__inner {
    @extend %list-unstyled;
    display: flex;
    flex-direction: column;
    gap: rem(10);

    li {
      line-height: 1.35;

      @include min(lg) {
        padding-right: rem(16);
      }
    }
  }

  a {
    @include hover {
      text-decoration: underline;
    }
  }
}

.footer-menu-bottom {
  @extend %list-unstyled;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: rem(15);

  @include min(md) {
    flex-direction: row;
  }

  @include min(lg) {
    gap: rem(20);
  }

  @include min(xl) {
    gap: rem(32);
  }

  &__wrapper {
    margin-bottom: rem(18);

    @include min(lg) {
      margin-bottom: rem(40);
    }

    @include min(xl) {
      padding-left: rem(16);
      margin-bottom: 0;
    }
  }

  a {
    font-size: rem(14);
    letter-spacing: 0.017em;

    @include min(lg) {
      font-size: rem(18);
      letter-spacing: 0.022em;
    }

    @include hover {
      text-decoration: underline;
    }
  }
}
