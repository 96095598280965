@include headings {
	min-height: 0vw; // safari fix
	color: $navy;
	font-family: $font-heading;
	font-weight: 400;
	line-height: 1.5;
	margin-top: 0;
	margin-bottom: 1rem;

	a {
		color: inherit;
	}
}

h1,
.h1 {
	font-size: rem(36);
	font-weight: 400;
	letter-spacing: 0.048em;

	@include min(lg) {
		font-size: rem(44);
		letter-spacing: 0;
	}
}

h2,
.h2 {
	font-size: rem(24);
	font-weight: 400;
	letter-spacing: 0.038em;

	@include min(lg) {
		font-size: rem(32);
	}
}

h3,
.h3 {
	font-size: rem(20);
	letter-spacing: 0.029em;

	@include min(lg) {
		font-size: rem(24);
	}
}

h4,
.h4 {
	font-size: rem(18);
}

h5,
.h5 {
	font-size: rem(16);
}

h6,
.h6 {
	font-size: rem(16);
}
