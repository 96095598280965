.btn {
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.1em;
	line-height: 24px;
	border-radius: 4px;
	padding: 8px 30px;

	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled).active:focus {
		box-shadow: 0 0 0 0.2rem rgba($blue2, 0.25);
	}

	&-primary {
		background-color: $blue;
		border-color: $blue;

		&:hover {
			background-color: $blue1;
			border-color: $blue1;
		}

		&.disabled,
		&:disabled {
			background-color: $blue;
			border-color: $blue;
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled):active:focus,
		&:not(:disabled):not(.disabled):focus {
			background-color: $blue2;
			border-color: $blue2;
			box-shadow: 0 0 0 0.2rem rgba($blue, 0.5);
		}
	}

	&-navy {
		color: $w !important;
		background-color: $navy;
		border-color: $navy;

		&:hover {
			background-color: #35434F;
			border-color: #35434F;
		}

		&.disabled,
		&:disabled {
			background-color: $grey-70;
			border-color: $grey-70;
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled):active:focus,
		&:not(:disabled):not(.disabled):focus {
			background-color: #35434F;
			border-color: #35434F;
			box-shadow: 0 0 0 0.2rem rgba($navy, 0.5);
		}
	}
}

.btn-link {
	color: $light-blue;
	font-size: rem(18);
	font-weight: 400;

	> span,
	> strong {
		font-size: inherit;
		font-weight: 400;
		letter-spacing: 0.02em;
		text-transform: initial;
	}

	.text-primary {
		color: inherit !important;
	}

	&:hover {
		color: $light-blue;

		> span:not(.text-primary),
		> strong:not(.text-primary) {
			text-decoration: underline;
		}
	}
}

.btn-link,
a.with-right-arrow {
	svg {
		width: rem(30);
		height: rem(30);
		top: -2px;
		left: -2px;
		transform: none !important;

		path {
			stroke: $light-blue !important;
		}
	}

	&:hover {
		svg path {
			stroke: $light-blue !important;
		}
	}

	&:focus,
	&:active {
		svg path {
			stroke: $blue2 !important;
		}
	}
}
