section.block-tabs-icon {
	> .section-hd {
		h6 {
			color: $secondary;
			font-weight: 700;
		}
	}

	> .section-bd {
		.cp-tabs-carousel-nav .slick-current {
			color: $secondary;

			.item .item-hd span {
				background-color: $secondary;

        img {
          opacity: 1 !important;
        }
			}
		}
	}
}
