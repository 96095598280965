// Mixins for Media Queries
// example usage: @include min(lg) || @include min(720)
// --- min-width
@mixin min($bp) {
	@if map-has-key($grid-breakpoints, $bp) {
		@media (min-width: map-get($grid-breakpoints, $bp)) {
			@content;
		}
	} @else {
		@media (min-width: #{$bp + 'px'}) {
			@content;
		}
	}
}

// --- min-height
@mixin minh($bp) {
	@if map-has-key($grid-breakpoints, $bp) {
		@media (min-height: map-get($grid-breakpoints, $bp)) {
			@content;
		}
	} @else {
		@media (min-height: #{$bp + 'px'}) {
			@content;
		}
	}
}

// --- max-width
@mixin max($bp) {
	@if map-has-key($grid-breakpoints, $bp) {
		$bp_max: map-get($grid-breakpoints, $bp) - 1;
		@media (max-width: $bp_max) {
			@content;
		}
	} @else {
		@media (max-width: #{$bp + 'px'}) {
			@content;
		}
	}
}

// --- max-height
@mixin maxh($bp) {
	@if map-has-key($grid-breakpoints, $bp) {
		$bp_max: map-get($grid-breakpoints, $bp) - 1;
		@media (max-height: $bp_max) {
			@content;
		}
	} @else {
		@media (max-height: #{$bp + 'px'}) {
			@content;
		}
	}
}
