.hero {
  overflow: hidden;

	@include max(md) {
		display: flex;
		flex-direction: column-reverse;
	}

	&:before {
		display: none !important;
	}

	&__bg {
		width: calc(100% + 48px);
		height: 56vw;
		max-height: rem(210);
    background-color: #fff;
    margin: 0 -24px;
		position: relative;

		@include min(md) {
      width: 100%;
      //width: 75vw;
			height: 100%;
			max-height: unset;
      margin: 0;
			//position: absolute;
      //top: 0;
      //right: 0;
		}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;

      @include min(md) {
        width: calc(100% + 220px);
        right: 40px;
      }
    }

		.mask-element {
			height: 100%;
			object-position: right;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
		}
	}

	&__content {
		height: auto;
		padding: 0;
		position: relative;
		z-index: 2;

    .container {
      @include max(991) {
        max-width: unset;
        padding: rem(0 24);
      }
    }

		&-decor,
    &-decor2 {
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			@include min(md) {
				width: 15vw;
				transform: none;
				z-index: 1;
			}

			@include min(1400) {
				width: calc(50vw - 600px + 1px);
			}
		}

    &-decor2 {
      left: calc(100% - 1px);
    }

		&-inner {
			max-width: unset;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: rem(40 0);
			position: relative;
      z-index: 2;

			@include min(md) {
				border-radius: rem(0 250 250 0);
				padding-right: 16%;
			}

			@include min(md) {
				min-height: rem(478);
			}

			h1 {
				padding-bottom: rem(20);
				margin-bottom: rem(23);
				position: relative;

				&:after {
					content: '';
					display: block;
					width: rem(60);
					height: 3px;
					background-color: $primary;
					border-radius: 3px;
					position: absolute;
					top: 100%;
					left: 0;
				}
			}
		}
	}
}
