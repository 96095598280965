section.block-configurator > .section-bd iframe.rowa-configurator {
  @include max(lg) {
    min-height: 590px !important;
  }
}

.embed-iframe-wrapper iframe.rowa-vmotion {
  @include max(lg) {
    min-height: 590px !important;
  }
}
